import React from 'react';
import '../styles/Contact.scss';
import Header from '../components/Header';
import Button from '../components/Button';
const Contact = () => {
  return (
    <>
      <Header showMenu={true} />
      <div className="contact-form">
        <form className="contact-form__body">
          <div className='contact-form-top'>

            <div className='contact-form-top-inputs'>

              <div className="contact-form__group">
                <input type="text" placeholder='Name' id="name" name="name" required />
              </div>

              <div className="contact-form__group">
                <input type="email" placeholder='Email' id="email" name="email" required />
              </div>

            </div>

            <div className='Contact-form-top-text'>
              <h2>HAVE A QUESTION?</h2>
              <h6>Fill out our form and we will get back to you!</h6>
            </div>

          </div>


          <div className="contact-form__group">
            <textarea id="message" name="message" rows="4" placeholder="Leave a message" required></textarea>
          </div>
          <Button text={'Send'} link={'whereweserve'} />

        </form>
      </div>
    </>
  );
};

export default Contact;
