import React, { useEffect, useRef } from 'react';
import '../styles/Home.scss';
import Footer from '../components/Footer';
import Header from '../components/Header';
import image1 from "../assets/Images/image 4.png";
import image2 from '../assets/Images/khinkali_rc-735x735-removebg 1.png';
import Button from '../components/Button';
import SideMenu from '../components/SideMenu';
import { useInView } from "framer-motion";
import { motion } from "framer-motion";

const Home = () => {
  const imageRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const value = window.scrollY * 0.25;
      if (imageRef.current) {
        imageRef.current.style.transform = `rotate(${value}deg)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const tasteRef = useRef(null);
  const middleTextRef = useRef(null);
  const middleGalleryRef = useRef(null);
  const bottomRef = useRef(null);

  const tasteInView = useInView(tasteRef, { once: true });
  const middleTextInView = useInView(middleTextRef, { once: true });
  const middleGalleryInView = useInView(middleGalleryRef, { once: true });
  const bottomInView = useInView(bottomRef, { once: true });

  return (
    <>
      <Header />
      <SideMenu />
      <div className="home">
        <section className="home__intro"></section>

        <motion.section
          className="home__taste"
          ref={tasteRef}
          style={{ visibility: tasteInView ? 'visible' : 'hidden' }}
          initial={{ y: 100, opacity: 0 }}
          animate={tasteInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <img src={image1} alt="Taste Image" className='home__taste-img' />
          <div className="home__taste-text">
            <h1>TASTE YOUR TASTE</h1>
            <p>"Catering" is a meticulous process aimed at providing passengers with a satisfying dining experience while flying. By working with chefs and nutritionists, "catering" designs menus that cater to diverse tastes and dietary restrictions. The main leading role is held by Georgian cuisine.</p>
            <Button text={'More About Us'} link={'about'} color='dark' />
          </div>
        </motion.section>

        <section className="home__middle">
          <motion.div
            className="home__middle-text"
            ref={middleTextRef}
            style={{ visibility: middleTextInView ? 'visible' : 'hidden' }}
            initial={{ y: 100, opacity: 0 }}
            animate={middleTextInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <h2>Customer Satisfaction</h2>
            <p>Target Aviation This is the fastest growing aviation market in the modern aviation market. Despite the increase in the demand for food, the number of suppliers and the quality of the catering supplier in Georgia did not increase at the same rate, which would lead to customer satisfaction. This became the driving force for our team to create a catering delivery platform that would allow us to increase the quality of the delivered catering, make it presentable and focused on the satisfaction of the client and the operator.</p>
            <Button text={'See how it turned out'} link={'whereweserve'} />
          </motion.div>

          <motion.div
            className="home__middle-gallery"
            ref={middleGalleryRef}
            style={{ visibility: middleGalleryInView ? 'visible' : 'hidden' }}
            initial={{ y: 100, opacity: 0 }}
            animate={middleGalleryInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <img ref={imageRef} src={image2} alt="Rolling Khinkalis" />
            <Button text={'Gallery'} link={'gallery'} />
          </motion.div>
        </section>

        <motion.section
          className="home__bottom"
          ref={bottomRef}
          style={{ visibility: bottomInView ? 'visible' : 'hidden' }}
          initial={{ y: 100, opacity: 0 }}
          animate={bottomInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div className="home__bottom-text">
            <h1>WANT TO WORK <br /> WITH US?</h1>
            <Button text={'Let’s get in touch'} link={'contact'} color='dark' />
          </div>
        </motion.section>
      </div>
      <Footer />
    </>
  );
};

export default Home;
