import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import '../styles/Header.scss'; // Ensure this file is imported for your styles
import Logo from './Logo';
import logo from '../assets/Images/Untitled design.png'
function Header({ text = '', showMenu = false }) {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setShow(false); // Hide when scrolling down
      } else {
        setShow(true); // Show when scrolling up
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div className={`header ${show ? 'show' : 'hide'}`}>
      <Link to='/'>
        <div className="header__logo">

          <img src={logo} alt='logo' />


        </div>
      </Link>
      {text !== '' && <h1>{text}</h1>}
      {showMenu && (
        <Link to="/menu">
          <svg className="header__menu" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="m21 15.75c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75zm0-4c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75zm0-4c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75z" fillRule="nonzero" />
          </svg>
        </Link>
      )}
    </div>
  );
}

export default Header;
