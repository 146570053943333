import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/Button.scss"
import airplane from '../assets/Images/airplane.svg'
const Button = ({ text, link, color = "" }) => {
    return (

        <>
            <Link to={`/${link}`}>
                <button className={`cta ${color}`}>
                    <span>{text}

                    </span>
                    <img src={airplane} />
                </button>
            </Link>

        </>
    );
};

export default Button;