import React from 'react';
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Menu from './components/Menu';
import WhereWeServe from './pages/WhereWeServe';
import Gallery from './pages/Gallery';
import About from './pages/About';

export const router = [
    {
        element: (
                <Home />
        ),
        path: '/',
    },
    {
        element: (
                <Contact />
        ),
        path: '/contact',
    },
    {
        element: (
          
                <Menu />
        ),
        path: '/menu',
    },
    {
        element: (
          
                <WhereWeServe />
        ),
        path: '/whereweserve',
    },
    {
        element: (
          
                <Gallery />
        ),
        path: '/gallery',
    },
    {
        element: (
          
                <About />
        ),
        path: '/about',
    }
];
