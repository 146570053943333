import React from 'react';

const Logo = ({ primaryColor = '#4B858E', secondaryColor = '#D4A72A', thirdColor = '#FFFFFF', fourthColor = '#A4A4A5' }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 595.28 595.28"
      style={{ enableBackground: 'new 0 0 595.28 595.28' }}
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`
        .st0{fill:${primaryColor};}
        .st1{fill:${secondaryColor};}
        .st2{fill:${thirdColor};}
        .st6{fill:${fourthColor};}
        `}
      </style>
      <rect y="-1230.22" className="st0" width="595.28" height="595.28" />
      <g>
        <g>
          <path className="st1" d="M234.74-794.66c-2.59,5.06-7.3,6.99-11.22,6.99c-6.11,0-12.27-3.56-12.27-10.64v-10.73
          c0-7.04,6.16-10.64,12.4-10.64c3.83,0,8.44,1.63,10.95,7.17l-6.42,2.59c-2.11-3.87-9.06-2.99-9.06,0.88v10.73
          c0,3.74,7.04,4.79,8.75,0.48L234.74-794.66z" />
          <path className="st1" d="M263.81-788.11h-8.44l-2.02-6.24h-8.31l-1.94,6.24h-8.44l10.86-31.14h7.17L263.81-788.11z M251.45-801.35
          l-2.15-9.1h-0.22l-2.29,9.1H251.45z" />
          <path className="st1" d="M276.92-811.82v23.71h-7.96v-23.71h-8.27v-7.48h24.5v7.48H276.92z" />
          <path className="st1" d="M296.22-807.02h12.62v6.82h-12.62v4.66h13.72v7.43h-21.68v-31.14h21.68v7.39h-13.72V-807.02z" />
          <path className="st1" d="M323.49-798.01h-1.41v9.9h-7.96v-31.14h12.45c6.42,0,11.39,2.95,11.57,10.25c0,5.76-2.33,9.02-6.11,10.2
          l8.49,10.69h-9.81L323.49-798.01z M326.7-804.78c4.97,0,4.97-7.12,0-7.12h-4.62v7.12H326.7z" />
          <path className="st1" d="M350.49-807.02h12.62v6.82h-12.62v4.66h13.72v7.43h-21.68v-31.14h21.68v7.39h-13.72V-807.02z" />
          <path className="st1" d="M377.76-798.01h-1.41v9.9h-7.96v-31.14h12.45c6.42,0,11.39,2.95,11.57,10.25c0,5.76-2.33,9.02-6.11,10.2
          l8.49,10.69h-9.81L377.76-798.01z M380.97-804.78c4.97,0,4.97-7.12,0-7.12h-4.62v7.12H380.97z" />
        </g>
        <g>
          <path className="st2" d="M179.7-913.09c-21.85-8.67-42.86-41.09-19.97-74.52c20.74-30.29,55.43-19.18,55.43-19.18
          c2.29-34.46,27.46-59.86,58.88-67.91c33.77-8.65,77.14,2.1,94.79,42.33c11.3,25.74-5.3,52.67-13.48,57.35
          c7.73-6.26,20.66-30.39,6.29-53.89c-11.76-19.22-35.66-31.94-58.65-31.95c-32.85-0.01-66.8,21.2-70.09,62.48
          c10.44,7.75,15.05,15.66,19.29,28.7c-11.01-28.93-57.36-32.84-77.42-7.28C156.24-953.33,163.09-929.5,179.7-913.09z" />
          <path className="st2" d="M385.29-949.55c12.08-18.03,44.85-28.93,54.78,3.17c7.25,23.44-16.69,48.6-48.65,47.01l-5.26,38.66
          c-1.58,11.1-11.49,15.05-23.02,13.46c-40.47-5.6-62.52-3.67-100.76,14.01c0,0,75.62-17.12,128.29,6.13l10.66-67.42
          c24.48,0,50.25-27.07,42.82-58.41c-4.8-18.46-17.28-27.9-30.56-27.04C396.93-978.89,384.45-965.5,385.29-949.55z" />
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="193.6845" y1="-915.9237" x2="391.4211" y2="-915.9237">
            <stop offset="0" style={{ stopColor: "#CD962B" }} />
            <stop offset="0.15" style={{ stopColor: "#865C47" }} />
            <stop offset="0.3" style={{ stopColor: "#E7B845" }} />
            <stop offset="0.4" style={{ stopColor: "#FDEB7B" }} />
            <stop offset="0.55" style={{ stopColor: "#E7B845" }} />
            <stop offset="0.7" style={{ stopColor: "#CD962B" }} />
            <stop offset="0.9178" style={{ stopColor: "#E6AE32" }} />
            <stop offset="1" style={{ stopColor: "#F1B937" }} />
          </linearGradient>
          <path className="st3" d="M193.68-897.98c3.42,20.53,10.64,49.86,22.14,58.97c30.19,23.92,134.73-36.51,175.59-159
          c-9.31,27.92-80.9,143.71-153.81,143.05C204.81-855.25,193.68-898.36,193.68-897.98z" />
          <path className="st1" d="M410.78-1003.18c-4.58-11.62-9.38-22.13-9.4-22.41c0.51-7.68,0.39-11.46-0.41-16.45
          c-0.56-2.87-2.15-5.75-3.8-5.67c-0.89,0.04-2.31,2.05-2.47,5.78c-0.09,2.28-0.27,5.53-0.4,8.43c-0.09,2.42-0.35,5.32-0.61,7.13
          c-0.13,0.14-16.25,15.43-18.4,17.88c-0.26,0.29-0.38,0.57-0.57,0.92c-0.18,0.63-0.21,1.25-0.25,1.94
          c2.34-1.36,15.91-8.45,18.33-9.74c-0.47,8.43-2.44,14.46-4.03,19.98c-1.05,3.71-1.29,4.48-1.92,5.61
          c-0.31,0.64-8.91,3.83-12.42,5.24c-0.54,0.23-0.94,0.46-1.11,1.23c3.08-0.5,6.22-1.07,9.43-1.65c3.41-0.65,3.74-1.02,5.07,0.78
          c1.64,2.47,3.21,4.87,4.78,7.27c0.07,0,0.37-0.78,0.28-1.19c-0.61-2.52-1.24-5.45-2.19-7.89c-0.41-1.29-0.64-1.69-0.32-2.33
          c4.76-9.54,7.17-16.35,9.15-25.07c0.16-0.84,0.16-0.97,0.16-0.97c0.79,0.72,8.44,11.5,9.32,12.63c0.44,0.53,1.7,2.19,1.92,2.52
          c0.18-0.49,0.42-1.33,0.52-1.96C411.49-1001.43,410.94-1002.85,410.78-1003.18z" />
        </g>
      </g>
      <g>
        <g>
          <path className="st1" d="M850.02-794.66c-2.59,5.06-7.3,6.99-11.22,6.99c-6.11,0-12.27-3.56-12.27-10.64v-10.73
          c0-7.04,6.16-10.64,12.4-10.64c3.83,0,8.44,1.63,10.95,7.17l-6.42,2.59c-2.11-3.87-9.06-2.99-9.06,0.88v10.73
          c0,3.74,7.04,4.79,8.75,0.48L850.02-794.66z" />
          <path className="st1" d="M879.09-788.11h-8.44l-2.02-6.24h-8.31l-1.93,6.24h-8.44l10.86-31.14h7.17L879.09-788.11z M866.73-801.35
          l-2.15-9.1h-0.22l-2.29,9.1H866.73z" />
          <path className="st1" d="M892.19-811.82v23.71h-7.96v-23.71h-8.27v-7.48h24.5v7.48H892.19z" />
          <path className="st1" d="M911.5-807.02h12.62v6.82H911.5v4.66h13.72v7.43h-21.68v-31.14h21.68v7.39H911.5V-807.02z" />
          <path className="st1" d="M938.77-798.01h-1.41v9.9h-7.96v-31.14h12.45c6.42,0,11.39,2.95,11.57,10.25c0,5.76-2.33,9.02-6.11,10.2
          l8.49,10.69h-9.81L938.77-798.01z M941.98-804.78c4.97,0,4.97-7.12,0-7.12h-4.62v7.12H941.98z" />
          <path className="st1" d="M965.77-807.02h12.62v6.82h-12.62v4.66h13.72v7.43h-21.68v-31.14h21.68v7.39h-13.72V-807.02z" />
          <path className="st1" d="M993.04-798.01h-1.41v9.9h-7.96v-31.14h12.45c6.42,0,11.39,2.95,11.57,10.25c0,5.76-2.33,9.02-6.11,10.2
          l8.49,10.69h-9.81L993.04-798.01z M996.25-804.78c4.97,0,4.97-7.12,0-7.12h-4.62v7.12H996.25z" />
        </g>
        <g>
          <path className="st0" d="M794.98-913.09c-21.85-8.67-42.86-41.09-19.97-74.52c20.74-30.29,55.43-19.18,55.43-19.18
          c2.29-34.46,27.46-59.86,58.88-67.91c33.77-8.65,77.14,2.1,94.79,42.33c11.3,25.74-5.3,52.67-13.48,57.35
          c7.73-6.26,20.66-30.39,6.29-53.89c-11.76-19.22-35.66-31.94-58.65-31.95c-32.85-0.01-66.8,21.2-70.09,62.48
          c10.44,7.75,15.05,15.66,19.29,28.7c-11.01-28.93-57.36-32.84-77.42-7.28C771.52-953.33,778.36-929.5,794.98-913.09z" />
          <path className="st0" d="M1000.57-949.55c12.08-18.03,44.85-28.93,54.78,3.17c7.25,23.44-16.69,48.6-48.65,47.01l-5.26,38.66
          c-1.58,11.1-11.49,15.05-23.02,13.46c-40.47-5.6-62.52-3.67-100.76,14.01c0,0,75.62-17.12,128.29,6.13l10.66-67.42
          c24.48,0,50.25-27.07,42.82-58.41c-4.8-18.46-17.28-27.9-30.56-27.04C1012.2-978.89,999.72-965.5,1000.57-949.55z" />
          <linearGradient id="SVGID_00000018221219127972080700000015673661794610536082_" gradientUnits="userSpaceOnUse" x1="808.9601" y1="-915.9237" x2="1006.6967" y2="-915.9237">
            <stop offset="0" style={{ stopColor: "#CD962B" }} />
            <stop offset="0.15" style={{ stopColor: "#865C47" }} />
            <stop offset="0.3" style={{ stopColor: "#E7B845" }} />
            <stop offset="0.4" style={{ stopColor: "#FDEB7B" }} />
            <stop offset="0.55" style={{ stopColor: "#E7B845" }} />
            <stop offset="0.7" style={{ stopColor: "#CD962B" }} />
            <stop offset="0.9178" style={{ stopColor: "#E6AE32" }} />
            <stop offset="1" style={{ stopColor: "#F1B937" }} />
          </linearGradient>
          <path style={{ fill: 'url(#SVGID_00000018221219127972080700000015673661794610536082_)' }} d="M808.96-897.98
          c3.42,20.53,10.64,49.86,22.14,58.97c30.19,23.92,134.73-36.51,175.59-159c-9.31,27.92-80.9,143.71-153.81,143.05
          C820.08-855.25,808.96-898.36,808.96-897.98z" />
          <path className="st1" d="M1026.06-1003.18c-4.58-11.62-9.38-22.13-9.4-22.41c0.51-7.68,0.39-11.46-0.41-16.45
          c-0.56-2.87-2.15-5.75-3.8-5.67c-0.89,0.04-2.31,2.05-2.47,5.78c-0.09,2.28-0.27,5.53-0.4,8.43c-0.09,2.42-0.35,5.32-0.61,7.13
          c-0.13,0.14-16.25,15.43-18.4,17.88c-0.26,0.29-0.38,0.57-0.57,0.92c-0.18,0.63-0.21,1.25-0.25,1.94
          c2.34-1.36,15.91-8.45,18.33-9.74c-0.47,8.43-2.44,14.46-4.03,19.98c-1.05,3.71-1.29,4.48-1.92,5.61
          c-0.31,0.64-8.91,3.83-12.42,5.24c-0.54,0.23-0.94,0.46-1.11,1.23c3.08-0.5,6.22-1.07,9.43-1.65c3.41-0.65,3.74-1.02,5.07,0.78
          c1.64,2.47,3.21,4.87,4.78,7.27c0.07,0,0.37-0.78,0.28-1.19c-0.61-2.52-1.24-5.45-2.19-7.89c-0.41-1.29-0.64-1.69-0.32-2.33
          c4.76-9.54,7.17-16.35,9.15-25.07c0.16-0.84,0.16-0.97,0.16-0.97c0.79,0.72,8.44,11.5,9.32,12.63c0.44,0.53,1.7,2.19,1.92,2.52
          c0.18-0.49,0.42-1.33,0.52-1.96C1026.77-1001.43,1026.21-1002.85,1026.06-1003.18z" />
        </g>
      </g>
      <rect x="615.56" y="-614.95" className="st0" width="595.28" height="595.28" />
      <g>
        <g>
          <path className="st1" d="M235.02-179.39c-2.59,5.06-7.3,6.99-11.22,6.99c-6.11,0-12.27-3.56-12.27-10.64v-10.73
          c0-7.04,6.16-10.64,12.4-10.64c3.83,0,8.44,1.63,10.95,7.17l-6.42,2.59c-2.11-3.87-9.06-2.99-9.06,0.88v10.73
          c0,3.74,7.04,4.79,8.75,0.48L235.02-179.39z" />
          <path className="st1" d="M264.09-172.84h-8.44l-2.02-6.24h-8.31l-1.94,6.24h-8.44l10.86-31.14h7.17L264.09-172.84z M251.74-186.07
          l-2.15-9.1h-0.22l-2.29,9.1H251.74z" />
          <path className="st1" d="M277.2-196.54v23.71h-7.96v-23.71h-8.27v-7.48h24.5v7.48H277.2z" />
          <path className="st1" d="M296.51-191.75h12.62v6.82h-12.62v4.66h13.72v7.43h-21.68v-31.14h21.68v7.39h-13.72V-191.75z" />
          <path className="st1" d="M323.77-182.73h-1.41v9.9h-7.96v-31.14h12.45c6.42,0,11.39,2.95,11.57,10.25c0,5.76-2.33,9.02-6.11,10.2
          l8.49,10.69h-9.81L323.77-182.73z M326.98-189.5c4.97,0,4.97-7.12,0-7.12h-4.62v7.12H326.98z" />
          <path className="st1" d="M350.78-191.75h12.62v6.82h-12.62v4.66h13.72v7.43h-21.68v-31.14h21.68v7.39h-13.72V-191.75z" />
          <path className="st1" d="M378.04-182.73h-1.41v9.9h-7.96v-31.14h12.45c6.42,0,11.39,2.95,11.57,10.25c0,5.76-2.33,9.02-6.11,10.2
          l8.49,10.69h-9.81L378.04-182.73z M381.26-189.5c4.97,0,4.97-7.12,0-7.12h-4.62v7.12H381.26z" />
        </g>
        <g>
          <path className="st0" d="M179.99-297.82c-21.85-8.67-42.86-41.09-19.97-74.52c20.74-30.29,55.43-19.18,55.43-19.18
          c2.29-34.46,27.46-59.86,58.88-67.91c33.77-8.65,77.14,2.1,94.79,42.33c11.3,25.74-5.3,52.67-13.48,57.35
          c7.73-6.26,20.66-30.39,6.29-53.89c-11.76-19.22-35.66-31.94-58.65-31.95c-32.85-0.01-66.8,21.2-70.09,62.48
          c10.44,7.75,15.05,15.66,19.29,28.7c-11.01-28.93-57.36-32.84-77.42-7.28C156.52-338.06,163.37-314.23,179.99-297.82z" />
          <path className="st0" d="M385.58-334.27c12.08-18.03,44.85-28.93,54.78,3.17c7.25,23.44-16.69,48.6-48.65,47.01l-5.26,38.66
          c-1.58,11.1-11.49,15.05-23.02,13.46c-40.47-5.6-62.52-3.67-100.76,14.01c0,0,75.62-17.12,128.29,6.13l10.66-67.42
          c24.48,0,50.25-27.07,42.82-58.41c-4.8-18.46-17.28-27.9-30.56-27.04C397.21-363.61,384.73-350.23,385.58-334.27z" />
          <path className="st1" d="M193.97-282.7c3.42,20.53,10.64,49.86,22.14,58.97c30.19,23.92,134.73-36.51,175.59-159
          c-9.31,27.92-80.9,143.71-153.81,143.05C205.09-239.97,193.97-283.08,193.97-282.7z" />
          <path className="st1" d="M411.06-387.91c-4.58-11.62-9.38-22.13-9.4-22.41c0.51-7.68,0.39-11.46-0.41-16.45
          c-0.56-2.87-2.15-5.75-3.8-5.67c-0.89,0.04-2.31,2.05-2.47,5.78c-0.09,2.28-0.27,5.53-0.4,8.43c-0.09,2.42-0.35,5.32-0.61,7.13
          c-0.13,0.14-16.25,15.43-18.4,17.88c-0.26,0.29-0.38,0.57-0.57,0.92c-0.18,0.63-0.21,1.25-0.25,1.94
          c2.34-1.36,15.91-8.45,18.33-9.74c-0.47,8.43-2.44,14.46-4.03,19.98c-1.05,3.71-1.29,4.48-1.92,5.61
          c-0.31,0.64-8.91,3.83-12.42,5.24c-0.54,0.23-0.94,0.46-1.11,1.23c3.08-0.5,6.22-1.07,9.43-1.65c3.41-0.65,3.74-1.02,5.07,0.78
          c1.64,2.47,3.21,4.87,4.78,7.27c0.07,0,0.37-0.78,0.28-1.19c-0.61-2.52-1.24-5.45-2.19-7.89c-0.41-1.29-0.64-1.69-0.32-2.33
          c4.76-9.54,7.17-16.35,9.15-25.07c0.16-0.84,0.16-0.97,0.16-0.97c0.79,0.72,8.44,11.5,9.32,12.63c0.44,0.53,1.7,2.19,1.92,2.52
          c0.18-0.49,0.42-1.33,0.52-1.96C411.77-386.15,411.22-387.57,411.06-387.91z" />
        </g>
      </g>
      <g>
        <g>
          <path className="st1" d="M850.3-179.39c-2.59,5.06-7.3,6.99-11.22,6.99c-6.11,0-12.27-3.56-12.27-10.64v-10.73
          c0-7.04,6.16-10.64,12.4-10.64c3.83,0,8.44,1.63,10.95,7.17l-6.42,2.59c-2.11-3.87-9.06-2.99-9.06,0.88v10.73
          c0,3.74,7.04,4.79,8.75,0.48L850.3-179.39z" />
          <path className="st1" d="M879.37-172.84h-8.44l-2.02-6.24h-8.31l-1.94,6.24h-8.44l10.86-31.14h7.17L879.37-172.84z M867.01-186.07
          l-2.15-9.1h-0.22l-2.29,9.1H867.01z" />
          <path className="st1" d="M892.47-196.54v23.71h-7.96v-23.71h-8.27v-7.48h24.5v7.48H892.47z" />
          <path className="st1" d="M911.78-191.75h12.62v6.82h-12.62v4.66h13.72v7.43h-21.68v-31.14h21.68v7.39h-13.72V-191.75z" />
          <path className="st1" d="M939.05-182.73h-1.41v9.9h-7.96v-31.14h12.45c6.42,0,11.39,2.95,11.57,10.25c0,5.76-2.33,9.02-6.11,10.2
          l8.49,10.69h-9.81L939.05-182.73z M942.26-189.5c4.97,0,4.97-7.12,0-7.12h-4.62v7.12H942.26z" />
          <path className="st1" d="M966.05-191.75h12.62v6.82h-12.62v4.66h13.72v7.43h-21.68v-31.14h21.68v7.39h-13.72V-191.75z" />
          <path className="st1" d="M993.32-182.73h-1.41v9.9h-7.96v-31.14h12.45c6.42,0,11.39,2.95,11.57,10.25c0,5.76-2.33,9.02-6.11,10.2
          l8.49,10.69h-9.81L993.32-182.73z M996.53-189.5c4.97,0,4.97-7.12,0-7.12h-4.62v7.12H996.53z" />
        </g>
        <g>
          <path className="st2" d="M795.26-297.82c-21.85-8.67-42.86-41.09-19.97-74.52c20.74-30.29,55.43-19.18,55.43-19.18
          c2.29-34.46,27.46-59.86,58.88-67.91c33.77-8.65,77.14,2.1,94.79,42.33c11.3,25.74-5.3,52.67-13.48,57.35
          c7.73-6.26,20.66-30.39,6.29-53.89c-11.76-19.22-35.66-31.94-58.65-31.95c-32.85-0.01-66.8,21.2-70.09,62.48
          c10.44,7.75,15.05,15.66,19.29,28.7c-11.01-28.93-57.36-32.84-77.42-7.28C771.8-338.06,778.65-314.23,795.26-297.82z" />
          <path className="st2" d="M1000.85-334.27c12.08-18.03,44.85-28.93,54.78,3.17c7.25,23.44-16.69,48.6-48.65,47.01l-5.26,38.66
          c-1.58,11.1-11.49,15.05-23.02,13.46c-40.47-5.6-62.52-3.67-100.76,14.01c0,0,75.62-17.12,128.29,6.13l10.66-67.42
          c24.48,0,50.25-27.07,42.82-58.41c-4.8-18.46-17.28-27.9-30.56-27.04C1012.49-363.61,1000.01-350.23,1000.85-334.27z" />
          <path className="st1" d="M809.24-282.7c3.42,20.53,10.64,49.86,22.14,58.97c30.19,23.92,134.73-36.51,175.59-159
          c-9.31,27.92-80.9,143.71-153.81,143.05C820.37-239.97,809.24-283.08,809.24-282.7z" />
          <path className="st1" d="M1026.34-387.91c-4.58-11.62-9.38-22.13-9.4-22.41c0.51-7.68,0.39-11.46-0.41-16.45
          c-0.56-2.87-2.15-5.75-3.8-5.67c-0.89,0.04-2.31,2.05-2.47,5.78c-0.09,2.28-0.27,5.53-0.4,8.43c-0.09,2.42-0.35,5.32-0.61,7.13
          c-0.13,0.14-16.25,15.43-18.4,17.88c-0.26,0.29-0.38,0.57-0.57,0.92c-0.18,0.63-0.21,1.25-0.25,1.94
          c2.34-1.36,15.91-8.45,18.33-9.74c-0.47,8.43-2.44,14.46-4.03,19.98c-1.05,3.71-1.29,4.48-1.92,5.61
          c-0.31,0.64-8.91,3.83-12.42,5.24c-0.54,0.23-0.94,0.46-1.11,1.23c3.08-0.5,6.22-1.07,9.43-1.65c3.41-0.65,3.74-1.02,5.07,0.78
          c1.64,2.47,3.21,4.87,4.78,7.27c0.07,0,0.37-0.78,0.28-1.19c-0.61-2.52-1.24-5.45-2.19-7.89c-0.41-1.29-0.64-1.69-0.32-2.33
          c4.76-9.54,7.17-16.35,9.15-25.07c0.16-0.84,0.16-0.97,0.16-0.97c0.79,0.72,8.44,11.5,9.32,12.63c0.44,0.53,1.7,2.19,1.92,2.52
          c0.18-0.49,0.42-1.33,0.52-1.96C1027.05-386.15,1026.5-387.57,1026.34-387.91z" />
        </g>
      </g>
      <rect x="613.05" className="st5" width="597.78" height="597.78" />
      <g>
        <g>
          <path className="st2" d="M853.62,427.64c-2.45,4.77-6.88,6.59-10.57,6.59c-5.76,0-11.57-3.36-11.57-10.03v-10.11
          c0-6.63,5.8-10.03,11.69-10.03c3.61,0,7.96,1.53,10.32,6.76l-6.05,2.45c-1.99-3.65-8.54-2.82-8.54,0.83v10.11
          c0,3.52,6.63,4.52,8.25,0.46L853.62,427.64z" />
          <path className="st2" d="M881.03,433.82h-7.96l-1.91-5.89h-7.84l-1.82,5.89h-7.96l10.24-29.35h6.76L881.03,433.82z M869.38,421.34
          l-2.03-8.58h-0.21l-2.16,8.58H869.38z" />
          <path className="st2" d="M893.38,411.48v22.35h-7.5v-22.35h-7.79v-7.05h23.09v7.05H893.38z" />
          <path className="st2" d="M911.58,415.99h11.9v6.43h-11.9v4.39h12.93v7.01h-20.44v-29.35h20.44v6.96h-12.93V415.99z" />
          <path className="st2" d="M937.28,424.49h-1.33v9.33h-7.5v-29.35h11.73c6.05,0,10.74,2.78,10.9,9.66c0,5.43-2.2,8.5-5.76,9.62l8,10.07
          h-9.24L937.28,424.49z M940.31,418.11c4.68,0,4.68-6.72,0-6.72h-4.35v6.72H940.31z" />
          <path className="st2" d="M962.74,415.99h11.9v6.43h-11.9v4.39h12.93v7.01h-20.44v-29.35h20.44v6.96h-12.93V415.99z" />
          <path className="st2" d="M988.44,424.49h-1.33v9.33h-7.5v-29.35h11.73c6.05,0,10.74,2.78,10.9,9.66c0,5.43-2.2,8.5-5.76,9.62l8,10.07
          h-9.24L988.44,424.49z M991.47,418.11c4.68,0,4.68-6.72,0-6.72h-4.35v6.72H991.47z" />
        </g>
        <path className="st6" d="M801.75,316.01c-20.59-8.18-40.4-38.74-18.83-70.24c19.55-28.56,52.25-18.08,52.25-18.08
        c2.16-32.48,25.88-56.42,55.5-64.01c31.84-8.16,72.71,1.98,89.35,39.9c10.65,24.27-4.99,49.65-12.7,54.06
        c7.29-5.9,19.48-28.65,5.93-50.8c-11.08-18.12-33.61-30.11-55.28-30.11c-30.96-0.01-62.96,19.98-66.07,58.9
        c9.84,7.31,14.19,14.76,18.18,27.05c-10.38-27.27-54.07-30.95-72.98-6.86C779.63,278.08,786.08,300.54,801.75,316.01z" />
        <path className="st6" d="M995.54,281.65c11.38-16.99,42.28-27.27,51.63,2.98c6.83,22.1-15.73,45.81-45.86,44.31l-4.96,36.44
        c-1.49,10.47-10.83,14.19-21.7,12.69c-38.15-5.28-58.93-3.46-94.97,13.21c0,0,71.28-16.14,120.93,5.78l10.05-63.55
        c23.07,0,47.37-25.51,40.37-55.06c-4.52-17.4-16.28-26.3-28.8-25.49C1006.5,253.99,994.74,266.61,995.54,281.65z" />
        <path className="st2" d="M814.92,330.26c3.23,19.36,10.03,47,20.87,55.59c28.46,22.55,127-34.41,165.52-149.88
        c-8.78,26.32-76.26,135.47-144.99,134.84C825.41,370.54,814.92,329.9,814.92,330.26z" />
        <path className="st2" d="M1019.56,231.09c-4.32-10.96-8.85-20.86-8.86-21.12c0.48-7.24,0.37-10.8-0.39-15.51
        c-0.53-2.7-2.03-5.42-3.58-5.34c-0.84,0.04-2.18,1.93-2.32,5.44c-0.09,2.15-0.26,5.21-0.38,7.95c-0.08,2.28-0.33,5.02-0.57,6.72
        c-0.12,0.14-15.32,14.54-17.34,16.86c-0.25,0.27-0.36,0.54-0.54,0.87c-0.17,0.59-0.2,1.18-0.23,1.83c2.21-1.28,15-7.97,17.27-9.19
        c-0.44,7.95-2.3,13.63-3.79,18.84c-0.99,3.49-1.22,4.22-1.81,5.29c-0.29,0.6-8.39,3.61-11.71,4.94c-0.51,0.22-0.89,0.43-1.05,1.16
        c2.9-0.47,5.86-1.01,8.89-1.55c3.22-0.62,3.53-0.96,4.78,0.73c1.55,2.33,3.02,4.59,4.5,6.86c0.06,0,0.35-0.73,0.27-1.12
        c-0.57-2.37-1.17-5.14-2.06-7.43c-0.39-1.21-0.6-1.59-0.31-2.19c4.48-9,6.76-15.41,8.62-23.63c0.16-0.79,0.15-0.92,0.15-0.92
        c0.75,0.68,7.95,10.84,8.78,11.9c0.41,0.5,1.6,2.06,1.81,2.38c0.17-0.46,0.39-1.25,0.49-1.84
        C1020.23,232.75,1019.71,231.41,1019.56,231.09z" />
      </g>
      <g>
        <g>
          <path className="st5" d="M238.35,427.64c-2.45,4.77-6.88,6.59-10.57,6.59c-5.76,0-11.57-3.36-11.57-10.03v-10.11
          c0-6.63,5.8-10.03,11.69-10.03c3.61,0,7.96,1.53,10.32,6.76l-6.05,2.45c-1.99-3.65-8.54-2.82-8.54,0.83v10.11
          c0,3.52,6.63,4.52,8.25,0.46L238.35,427.64z" />
          <path className="st5" d="M265.75,433.82h-7.96l-1.91-5.89h-7.84l-1.82,5.89h-7.96l10.24-29.35h6.76L265.75,433.82z M254.1,421.34
          l-2.03-8.58h-0.21l-2.16,8.58H254.1z" />
          <path className="st5" d="M278.1,411.48v22.35h-7.5v-22.35h-7.79v-7.05h23.09v7.05H278.1z" />
          <path className="st5" d="M296.3,415.99h11.9v6.43h-11.9v4.39h12.93v7.01H288.8v-29.35h20.44v6.96H296.3V415.99z" />
          <path className="st5" d="M322.01,424.49h-1.33v9.33h-7.5v-29.35h11.73c6.05,0,10.74,2.78,10.9,9.66c0,5.43-2.2,8.5-5.76,9.62l8,10.07
          h-9.24L322.01,424.49z M325.03,418.11c4.68,0,4.68-6.72,0-6.72h-4.35v6.72H325.03z" />
          <path className="st5" d="M347.46,415.99h11.9v6.43h-11.9v4.39h12.93v7.01h-20.44v-29.35h20.44v6.96h-12.93V415.99z" />
          <path className="st5" d="M373.16,424.49h-1.33v9.33h-7.5v-29.35h11.73c6.05,0,10.74,2.78,10.9,9.66c0,5.43-2.2,8.5-5.76,9.62l8,10.07
          h-9.24L373.16,424.49z M376.19,418.11c4.68,0,4.68-6.72,0-6.72h-4.35v6.72H376.19z" />
        </g>
        <path className="st5" d="M186.47,316.01c-20.59-8.18-40.4-38.74-18.83-70.24c19.55-28.56,52.25-18.08,52.25-18.08
        c2.16-32.48,25.88-56.42,55.5-64.01c31.84-8.16,72.71,1.98,89.35,39.9c10.65,24.27-4.99,49.65-12.7,54.06
        c7.29-5.9,19.48-28.65,5.93-50.8c-11.08-18.12-33.61-30.11-55.28-30.11c-30.96-0.01-62.96,19.98-66.07,58.9
        c9.84,7.31,14.19,14.76,18.18,27.05c-10.38-27.27-54.07-30.95-72.98-6.86C164.35,278.08,170.81,300.54,186.47,316.01z" />
        <path className="st5" d="M380.26,281.65c11.38-16.99,42.28-27.27,51.63,2.98c6.83,22.1-15.73,45.81-45.86,44.31l-4.96,36.44
        c-1.49,10.47-10.83,14.19-21.7,12.69c-38.15-5.28-58.93-3.46-94.97,13.21c0,0,71.28-16.14,120.93,5.78l10.05-63.55
        c23.07,0,47.37-25.51,40.37-55.06c-4.52-17.4-16.28-26.3-28.8-25.49C391.23,253.99,379.47,266.61,380.26,281.65z" />
        <path className="st5" d="M199.65,330.26c3.23,19.36,10.03,47,20.87,55.59c28.46,22.55,127-34.41,165.52-149.88
        c-8.78,26.32-76.26,135.47-144.99,134.84C210.13,370.54,199.65,329.9,199.65,330.26z" />
        <path className="st5" d="M404.29,231.09c-4.32-10.96-8.85-20.86-8.86-21.12c0.48-7.24,0.37-10.8-0.39-15.51
        c-0.53-2.7-2.03-5.42-3.58-5.34c-0.84,0.04-2.18,1.93-2.32,5.44c-0.09,2.15-0.26,5.21-0.38,7.95c-0.08,2.28-0.33,5.02-0.57,6.72
        c-0.12,0.14-15.32,14.54-17.34,16.86c-0.25,0.27-0.36,0.54-0.54,0.87c-0.17,0.59-0.2,1.18-0.23,1.83c2.21-1.28,15-7.97,17.27-9.19
        c-0.44,7.95-2.3,13.63-3.8,18.84c-0.99,3.49-1.22,4.22-1.81,5.29c-0.29,0.6-8.39,3.61-11.71,4.94c-0.51,0.22-0.89,0.43-1.05,1.16
        c2.9-0.47,5.86-1.01,8.89-1.55c3.22-0.62,3.53-0.96,4.78,0.73c1.55,2.33,3.02,4.59,4.5,6.86c0.06,0,0.35-0.73,0.27-1.12
        c-0.57-2.37-1.17-5.14-2.06-7.43c-0.39-1.21-0.6-1.59-0.31-2.19c4.48-9,6.76-15.41,8.62-23.63c0.16-0.79,0.15-0.92,0.15-0.92
        c0.75,0.68,7.95,10.84,8.78,11.9c0.41,0.5,1.6,2.06,1.81,2.38c0.17-0.46,0.39-1.25,0.49-1.84
        C404.96,232.75,404.43,231.41,404.29,231.09z" />
      </g>
    </svg>
  );
};

export default Logo;
