import React from 'react';
import '../styles/Menu.scss';
import { Link } from 'react-router-dom';

class Menu extends React.Component {
    render() {
        return (
            <main>
                <nav className="menu">
                    <Link to="/">
                        <a className="menu__item">
                            <span className="menu__item-text"><span className="menu__item-textinner"> <i>Home</i></span></span>
                            <span className="menu__item-sub">Go to Home Page</span>
                        </a>
                    </Link>
                    <Link to="/about">
                        <a className="menu__item" >
                            <span className="menu__item-text"><span className="menu__item-textinner"><i>About</i></span></span>
                            <span className="menu__item-sub">More about Us</span>
                        </a>
                    </Link>
                    <Link to="/whereweserve">
                        <a className="menu__item" >
                            <span className="menu__item-text"><span className="menu__item-textinner"><i>Where We Serve</i></span></span>
                            <span className="menu__item-sub">Dive into projects</span>
                        </a>
                    </Link>
                    <Link to="/gallery">
                        <a className="menu__item" >
                            <span className="menu__item-text"><span className="menu__item-textinner"><i>Gallery</i></span></span>
                            <span className="menu__item-sub">Our Gallery</span>
                        </a>
                    </Link>
                    <Link to="/contact">
                        <a className="menu__item" >
                            <span className="menu__item-text"><span className="menu__item-textinner"><i>Contact</i></span></span>
                            <span className="menu__item-sub">Want to reach out?</span>
                        </a>
                    </Link>
                </nav>
            </main>
        );
    }
}

export default Menu;
