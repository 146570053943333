import { ReactLenis, useLenis } from 'lenis/react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';

  
import { router } from './router';
import './App.css'
function App() {
  const lenis = useLenis(({ scroll }) => {
    // called every scroll
  })



  return (
    <ReactLenis root>
      <RouterProvider router={createBrowserRouter(router)} />
    </ReactLenis>
  )
}

export default App;