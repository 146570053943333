import React from 'react';
import '../styles/Footer.scss'
import Logo from './Logo';
import { Link } from 'react-router-dom';
import logo from '../assets/Images/Untitled design.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img src={logo} alt='logo' />
        </div>
        <div className="footer-links">
          <ul>
            <Link to="/"> <li>Home</li> </Link>
            <Link to="/about"><li>Our Story</li></Link>
            <Link to="/whereweserve"><li>Where we serve</li></Link>
            <Link to="/gallery"><li>Gallery</li></Link>
            <Link to="/contact"><li>Contact us</li></Link>
          </ul>
        </div>
        <div className="footer-contact">
          <p>
            <a href="tel:+955553535353">+955 553 53 53 53</a>
          </p>
          <p>
            <a href="mailto:test@gmail.com">test@gmail.com</a>
          </p>
          <p>Crta. Palma - Pollença PM 220</p>
          <p>Pollença · Mallorca</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
