import React from 'react';
import '../styles/SideMenu.scss';
import { FaUtensils, FaPercentage, FaEnvelope } from 'react-icons/fa'; // You can replace these with the appropriate icons if needed

const SideMenu = () => {
  return (
    <div className="stacked-buttons">
      <div className="button">
        <FaUtensils />
      </div>
      <div className="button">
        <FaPercentage />
      </div>
      <div className="button">
        <FaEnvelope />
      </div>
    </div>
  );
};

export default SideMenu;
