import React from 'react';
import '../styles/Gallery.scss';
import Footer from '../components/Footer';
import Header from '../components/Header';
import image1 from "../assets/Images/image 4.png"

const images = [
  // Array of image URLs
  '../assets/Images/image 4.png',
  '../assets/Images/image 4.png',
  '../assets/Images/image 4.png',
  '../assets/Images/image 4.png',
  '../assets/Images/image 4.png',
  '../assets/Images/image 4.png',
  '../assets/Images/image 4.png',
  '../assets/Images/image 4.png'
];

const Gallery = () => {
  return (
    <>
      <Header text='GALLERY' showMenu={true} />
      <div className="gallery">
        {images.map((image, index) => (
          <div key={index} className="gallery__item">
            <img src={image1} alt={`Gallery item ${index + 1}`} loading="lazy" className="gallery__image" />
            <div className="gallery__overlay">
              <div className="gallery__text">Photo Description</div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>

  );
};

export default Gallery;
