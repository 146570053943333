import React, { useRef } from 'react';
import '../styles/About.scss';
import Footer from '../components/Footer';
import Header from '../components/Header';
import image8 from '../assets/Images/image 8.png';
import image9 from '../assets/Images/image 9.png';
import image10 from '../assets/Images/image 10.png';
import image11 from '../assets/Images/image 11.png';
import image12 from '../assets/Images/image 12.png';
import { useInView } from "framer-motion";
import { motion } from "framer-motion";

const About = () => {
  const introRef = useRef(null);
  const detailsRef = useRef(null);
  const middleRef = useRef(null);
  const summaryRef = useRef(null);

  const introInView = useInView(introRef, { once: true });
  const detailsInView = useInView(detailsRef, { once: true });
  const middleInView = useInView(middleRef, { once: true });
  const summaryInView = useInView(summaryRef, { once: true });

  return (
    <div className="about">
      <Header showMenu={true} />

      <div className='about__image'></div>

      <section className="about__main">
        <div className="about__intro">
          <h1>OUR STORY</h1>
          <p>
            <strong>Caterer</strong> is a platform that allows the aircraft operator to pre-determine the desired food volume, amount and cost for his flight, without any <em>hidden costs, unexpected surprises</em>, at the scheduled time and at the airport to receive the ordered catering on board the aircraft.
          </p>
        </div>


        <motion.section className="about__details" ref={detailsRef}
          style={{ visibility: detailsInView ? 'visible' : 'hidden' }}
          initial={{ y: 100, opacity: 0 }}
          animate={detailsInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div>
            <p>
              The many years of experience accumulated in the aviation market showed us that despite the significant increase in the share of business aviation in the aviation segment, it did not lead to the creation of a high-class and quality on-board catering company in Skartvelo, which harmed the reputation of the famous Georgian hospitality, and also indicated a certain backwardness in the Georgian aviation market in this regard.
            </p>
            <img src={image8} alt='Cake' loading='lazy' />
          </div>
          <div>
            <img src={image9} alt='Cake' loading='lazy' />
          </div>
          <div>
            <img src={image11} alt='Cake' loading='lazy' />
            <p>
              The first location and the first catering platform that the caterer will launch on the aviation market is Tbilisi International Airport.
            </p>
          </div>
        </motion.section>

        <motion.section className="about__middle" ref={middleRef}
          style={{ visibility: middleInView ? 'visible' : 'hidden' }}
          initial={{ y: 100, opacity: 0 }}
          animate={middleInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <p>
            The catering platform <a href="http://www.flyingchef.ge" target="_blank" rel="noopener noreferrer">www.flyingchef.ge</a> is an exclusive, high-class catering. Ronelli is also designed and individually produced by Levan Kobiashvili. Each dish that you will see on our platform is a combination of long-term experience, synthesis of traditional Georgian flavors, sensations obtained by the national methods of preparing the dish, which will explode your taste receptors.
          </p>
          <img src={image10} loading="lazy" alt="Chef working" />
        </motion.section>

        <motion.section className='about__summary' ref={summaryRef}
          style={{ visibility: summaryInView ? 'visible' : 'hidden' }}
          initial={{ y: 100, opacity: 0 }}
          animate={summaryInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <img src={image12} loading="lazy" alt="Man in airplane having fun" />
          <p>
            The website <a href="http://www.flyingchef.ge" target="_blank" rel="noopener noreferrer">www.flyingchef.ge</a> is minimalistic and directly focused on the selection, ordering and delivery of in-flight catering. Any food or drink placed on the platform is available to the client. Along with the name of the dish, the client receives full information about the history of the dish's creation, what taste it has, how to serve it, its weight, and the sommelier's advice on which wine to taste it with. In the application, you will also find the matching catering selection of the meal, after which the customer must make.
          </p>
        </motion.section>
      </section>
      <Footer />
    </div>
  );
};

export default About;
