import React from 'react';
import '../styles/WhereWeServe.scss';
import Footer from '../components/Footer';
import airport from '../assets/Images/airport.jpg'
import Header from '../components/Header';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import MapComponent from '../components/MapComponent';
const WhereWeServe = () => {
 

  return (
    <>
      <Header text='WHERE WE SERVE' showMenu={true} />
      <div className="where-we-serve">
        <section className="where-we-serve__content">
          <img src={airport} alt="Tbilisi International Airport" />
          <div className="details">
            <h3>Tbilisi International Airport</h3>
            <p>
              <i>www.flyingchef.ge</i>
            </p>
            <p>
              <strong>Please note</strong> that any catering order should be ordered at least 18 hours prior to delivery.
              Any order less than this time will need final confirmation by chef.
            </p>
          </div>

        </section>
        <div className="map">
          <MapComponent />

        </div>

      </div>
      <Footer />
    </>
  );
};

export default WhereWeServe;
